import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser'; import { CommonModule } from '@angular/common';
import { CommonService } from '../../../../../../src/shared/common.service';
import { SharedModule } from '../../../../../../src/shared/shared.module';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LeaveHistoryComponent } from '../../leave-history/leave-history.component';
import { NgxEditorModule, Editor, Toolbar } from 'ngx-editor';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-leave-form',
  standalone: true,
  imports: [
    CommonModule, SharedModule, TranslateModule, LeaveHistoryComponent, NgxEditorModule
  ],
  templateUrl: './leave-form.component.html',
  styleUrls: ['./leave-form.component.css'],

})
export class LeaveFormComponent implements OnInit {
  public form_validation = "";
  public manageForm: any = FormGroup;
  public employeesList: any = [];
  public leaveTypes: any = [];
  public leave_info: any = {}
  public manageFormSubmited = false;
  public detailsprofilePic: any = '';
  public weekoffHolidayCount: any = [];
  public leaveTypeSettings: any = {};
  public empleavebalances: any = [];
  public empSelEditMode = false;
  public currentYear: number = 0;
  formData: any = { form_type: 'a', form_id: '' };
  public recordDetails: any = {};
  public minStartDate: any = moment().startOf('month').format('YYYY-MM-DD');
  public minEndDate: any = new Date();
  public maxStartdDate: any = new Date();
  public maxEndDate: any = new Date();
  public records_loaded = 0;
  public leave_details_list = [];
  public emp_leave_type_details: any = [];
  public inintialFilterValue: any = {};
  public dialogRef: any;
  public holidaysList: any = [];
  public formarraylength = 0;
  public editor1!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  @Input() set form_data(data: any) {
    this.formData = data;
    if (this.formData.form_type == 'e') {
      this.loadRecordDetailsAndLeaveInfo();
      // this.getLeaveInfo();
    }
    else {
      this.getRecordDetails()
      this.addLeaveTransactions('')
    }
  }
  public leavebalancesList: any = [];
  public dateRange: any = []

  @ViewChild("fileViewerRef") fileViewerRef!: TemplateRef<any>;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() formCloseEvent = new EventEmitter<any>();
  constructor(public sanitizer: DomSanitizer, public cs: CommonService, public dialog: MatDialog, private formBuilder: FormBuilder) {

    this.manageForm = this.formBuilder.group({
      leave_id: [0],
      employee_id: [this.cs.userSession.user_id],
      leave_type_id: [0],
      start_dt: [new Date()],
      // Validators.required
      end_dt: [new Date()],
      no_of_days: [{ value: null, disabled: true }],
      leave_reason: ['', Validators.required],
      halfday_on_start_dt: [false],
      halfday_on_end_dt: [false],
      shift_id: [],
      action_by: [],
      action_remarks: [''],
      status: [],
      emp_id: [],
      emp_code: [this.cs.userSession.employee_code],
      emp_name: [this.cs.userSession.employee_name],
      emp_disgnation: [this.cs.userSession.account_role_name],
      emp_img: [this.cs.userSession['account_img']],
      emp_week_off: [this.cs.userSession.emp_weekly_off],
      start_dt_formated: [''],
      end_dt_formated: [''],
      past_days: [],
      future_days: [],
      leave_transactions: this.formBuilder.array([]),
      leave_details_type: this.formBuilder.array([]),
      is_maternity_leave: [false]
    });
    this.inintialFilterValue = this.manageForm.getRawValue();
    // this.getRecordDetails();
    this.viewLeaveBalance();
  }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  getControls() {
    return (this.manageForm.get('leave_transactions') as FormArray);
  }
  public leaveBalancesList(dta: any) {
    this.leavebalancesList = dta;
  }
  CloseForm(action: any) {
    this.formCloseEvent.emit({ type: action });
  }

  async getRecordDetails() {
    try {
    let postDta = [
      {
        scheema: "hr", procname: "get_employees_dropdown", vals: ["", this.cs.userSession.org_id, this.cs.userSession.user_id, 50, 0
          , this.cs.userSession.account_role_order, this.cs.access_rights.subordinate_record_access_level]
      },
      { scheema: "hr", procname: "get_emp_applicable_leave_types_info", vals: [this.cs.userSession.user_id, this.cs.userSession.org_id] },
      { scheema: "hr", procname: "get_holidays_list", vals: ['', this.cs.userSession.org_id, 20, 0] }];

    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/caldbprocfns", info: postDta }));
    this.employeesList = result['data']['get_employees_dropdown'];
    this.holidaysList = result['data']['get_holidays_list'];

    this.leaveTypes = result['data']['get_emp_applicable_leave_types_info'];
    // this.updateNoofDays(1);
  }
  catch (error) {
    console.error('Error fetching record details:', error);
    throw error;  // Ensure to throw error to handle it in the calling function
  }
}
  async loadRecordDetailsAndLeaveInfo() {
    try {
      await this.getRecordDetails();
  
      await this.getLeaveInfo();
    } catch (error) {
      console.error('Error in loading data:', error);
    }
  }
  async getLeaveInfo() {
    try {
    this.leave_info = {};
    // let empEduInfo=[];
    let postDta =
      [
        {
          scheema: "hr", procname: "get_leave_info",
          vals: [this.formData.form_id, this.cs.userSession.org_id]
        },
        {
          scheema: "hr", procname: "get_leave_details_list",
          vals: [this.formData.form_id, this.cs.userSession.org_id]
        },
      ];
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/caldbprocfns", info: postDta }));
    this.leave_info = result['data']['get_leave_info'][0];
    let leavetransactions = result['data']['get_leave_info'];
    this.leave_details_list = result['data']['get_leave_details_list']

    this.manageForm.patchValue(this.leave_info);
    
    for (var i in this.leave_details_list) {
      this.addLeaveTransactions(this.leave_details_list[i]);
      this.datesChanges(this.leave_details_list[i],i);
    }
    // this.manageForm.patchValue(this.leave_info,leavetransactions)
    setTimeout(() => {
      this.recordDetails = { emp_id: this.leave_info.employee_id, leaveId: this.leave_info.leave_type_id };
      this.records_loaded = 1;
    }, 50);
  }catch (error) {
    console.error('Error fetching leave info:', error);
  }
  }
datesChanges(data:any,i:any){
  console.log(data)
  const leaveTransactionsArray = <FormArray>this.manageForm.get('leave_details_type');
  const control = <FormGroup>leaveTransactionsArray.at(i);
  const leaveBalance = this.leaveTypes.filter((x: any) => x.leave_type_id === data.leave_type_id)[0];

 if(this.leaveTypeSettings.leave_no_of_days>=moment(data.end_dt).diff(moment(data.start_dt), 'days')){
  if ((leaveBalance['past_dates'] != null && leaveBalance['past_dates'] != 0) && (data.start_dt>=new Date() || data.end_dt>=new Date())) {
    control.patchValue({ 'min_start_date': new Date(moment().subtract(leaveBalance['past_dates'], 'days').format('YYYY-MM-DD')) })
  }
  if ((leaveBalance['future_dates'] != null && leaveBalance['future_dates'] != 0) && (data.start_dt>=new Date() || data.end_dt>=new Date())) {
    control.patchValue({
      'min_end_date': new Date(moment().add(leaveBalance['future_dates'], 'days').format('YYYY-MM-DD')),
      'max_end_date': new Date(moment().add(leaveBalance['future_dates'], 'days').format('YYYY-MM-DD'))
    })
   }
 }
}

  public async employeeDetails() {
    var index = this.employeesList.findIndex((x: any) => x.employee_id == this.manageForm.getRawValue().employee_id);
    //this.detailsprofilePic = '';
    // if (this.employeesList[index]['account_img'] != '' && this.employeesList[index]['account_img'] != null) {
    //   try {
    //     let result = await lastValueFrom(this.cs.getfile('profile', this.employeesList[index]['account_img']));
    //    this.detailsprofilePic = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
    //   } catch (error) {

    //   }
    // }
    this.manageForm.patchValue({
      employee_id: this.employeesList[index]['employee_id'],
      emp_code: this.employeesList[index]['employee_code'],
      emp_name: this.employeesList[index]['employee_name'],
      emp_disgnation: this.employeesList[index]['designation_name'],
      emp_week_off: this.employeesList[index]['emp_weekly_off'],
      emp_img: this.employeesList[index]['account_img'],
      is_maternity_leave: this.employeesList[index]['eligible_for_maternity_leave']
    });
  }

  async getEmpLeaveTypeDetails() {
    let postDta =
    {
      scheema: "hr", procname: "get_emp_leave_type_details",
      vals: [this.manageForm.getRawValue().employee_id, this.manageForm.getRawValue().leave_type_id, this.cs.userSession.org_id]
    };
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
    if (result['data'].length > 0) {
      this.emp_leave_type_details = result['data'];
    } else {

    }
    this.records_loaded = 0;
    setTimeout(() => {
      this.recordDetails = { emp_id: this.manageForm.getRawValue().employee_id, leaveId: this.manageForm.getRawValue().leave_type_id, isfullLeaveHistoryTable: false };
      this.records_loaded = 1;
    }, 50);
  }
  // new functions
  viewLeaveBalance() {
    this.recordDetails = { emp_id: this.manageForm.getRawValue().employee_id, leaveId: 0, formtype: 'leave' };
  }
  updateLeaveTypeSettings(event: any, jpos: any) {
    const leaveTransactionsArray = <FormArray>this.manageForm.get('leave_details_type');
    const control = <FormGroup>leaveTransactionsArray.at(jpos);
    let leavetypesetting = this.leaveTypes.filter((x: any) => x.leave_type_id === event.value)[0];
    if (+leavetypesetting['allow_half_day'] == 0 && jpos == 0) {
      this.manageForm.get('halfday_on_start_dt').disable();
      this.manageForm.get('halfday_on_end_dt').disable();
    }
    else if (+leavetypesetting['allow_half_day'] == 1 && jpos == 0) {
      this.manageForm.get('halfday_on_start_dt').enable();
      this.manageForm.get('halfday_on_end_dt').enable();
    }
    else if (+leavetypesetting['allow_half_day'] == 0 && jpos == leaveTransactionsArray.length - 1) {
      this.manageForm.get('halfday_on_end_dt').disable();
    }
    else if (+leavetypesetting['allow_half_day'] == 1 && jpos == leaveTransactionsArray.length - 1) {
      this.manageForm.get('halfday_on_end_dt').enable();
    }

    if (leavetypesetting['past_dates'] != null && leavetypesetting['past_dates'] != 0) {
      leaveTransactionsArray.at(jpos).patchValue({ 'min_start_date': new Date(moment().subtract(leavetypesetting['past_dates'], 'days').format('YYYY-MM-DD')) })
    }
    if (leavetypesetting['future_dates'] != null && leavetypesetting['future_dates'] != 0) {
      leaveTransactionsArray.at(jpos).patchValue({
        'min_end_date': new Date(moment().add(leavetypesetting['future_dates'], 'days').format('YYYY-MM-DD')),
        'max_end_date': new Date(moment().add(leavetypesetting['future_dates'], 'days').format('YYYY-MM-DD'))
      })
    }
  }
  deleteLeaveType(i: any) {
    let control = <FormArray>this.manageForm.controls['leave_details_type'];
    this.validate(i)
    control.removeAt(i);
    // this.revalidateFormArray()
  }
  validate(i: any) {
    this.manageForm.controls.leave_details_type.at(i).controls['leave_type_id'].clearValidators();
    this.manageForm.controls.leave_details_type.at(i).controls['start_dt'].clearValidators();
    this.manageForm.controls.leave_details_type.at(i).controls['end_dt'].clearValidators();

    this.manageForm.controls.leave_details_type.at(i).controls['leave_type_id'].updateValueAndValidity();
    this.manageForm.controls.leave_details_type.at(i).controls['start_dt'].updateValueAndValidity();
    this.manageForm.controls.leave_details_type.at(i).controls['end_dt'].updateValueAndValidity();
  }
  async updateNoofDays(jpos: any) {
    const leaveTransactionsArray = <FormArray>this.manageForm.get('leave_details_type');
    const control = <FormGroup>leaveTransactionsArray.at(jpos);
    const fromDate = moment(control.value.start_dt);
    const toDate = moment(control.value.end_dt);
    const leaveType = control.value.leave_type_id;
    this.leaveTypeSettings = this.leaveTypes.filter((x: any) => x.leave_type_id === leaveType)[0];

    if ((fromDate && toDate && fromDate <= toDate)) {
      const halfDayStart = this.manageForm.value.halfday_on_start_dt;
      const halfDayEnd = this.manageForm.value.halfday_on_end_dt;
      let fulltotalDays = moment.duration(toDate.diff(fromDate)).asDays() + 1;
      let totalDays = fulltotalDays;
      if (jpos === 0 && halfDayStart) {
        totalDays -= 0.5;
      }
      if (jpos === leaveTransactionsArray.length - 1 && halfDayEnd) {
        totalDays -= 0.5;
      }
      control.patchValue({
        start_dt: moment(moment(fromDate).format('YYYY-MM-DD')).format('YYYY-MM-DD'), end_dt: moment(moment(toDate).format('YYYY-MM-DD')).format('YYYY-MM-DD')
      })
      const paidLeave = this.leaveTypeSettings.is_loss_of_pay == false ? 1 : 0;
      let isPaidLeave = control.value.is_paid_leave;

      if (paidLeave > 0) {
        isPaidLeave = Math.max(isPaidLeave - (halfDayStart ? 0.5 : 0) - (halfDayEnd ? 0.5 : 0), 0);
        control.patchValue({ is_paid_leave: isPaidLeave });
      }
      let weekofflist = [];
      let publicHldys = []
      for (let currentDate = fromDate; currentDate <= toDate; currentDate.add(1, 'days')) {
        const formattedDate = currentDate.format('YYYY-MM-DD');
        const weekOffDay = (this.cs.weeksList[+this.cs.userSession.emp_weekly_off - 1] && this.cs.weeksList[+this.cs.userSession.emp_weekly_off - 1].name);
        let holidayfilter = this.holidaysList.filter((x: any) => moment(x.holiday_date).format('YYYY-MM-DD') === formattedDate)[0];

        if (((moment(currentDate).day() === 0) || (moment(currentDate).format('dddd') === weekOffDay) || (currentDate.day() === 6 && currentDate.date() >= 8 && currentDate.date() <= 14))&& this.leaveTypes.weekend_between_leaves==1) {
          weekofflist.push(formattedDate);
        }
        else if (holidayfilter && this.leaveTypes.holidays_between_leaves==1) {
          publicHldys.push(formattedDate);
        }
      }
      control.patchValue({
        no_of_days: totalDays - (weekofflist.length + publicHldys.length)
      })
      control.patchValue({ is_holiday: weekofflist.join(','), is_pub_holiday: publicHldys.join(',') })
      for (var ix = jpos + 1; ix <= leaveTransactionsArray.length; ix++) {
        this.deleteLeaveType(ix);
      }
    }

  }
  addLeaveTransactions(dta: any = '') {
    let control = <FormArray>(this.manageForm.controls['leave_details_type']);
    this.formarraylength = control.length;
    if (dta != '') {
      control.push(
        this.formBuilder.group({
          leave_detail_id: dta['leave_detail_id'],
          leave_id: dta['leave_id'],
          leave_type_id: dta['leave_type_id'],
          is_paid_leave: dta['is_paid_leave'],
          is_half_day: (dta['is_half_day'] == true ? 1 : 0),
          allow_half_day: (dta['is_half_day'] == 1 ? 1 : 0),
          start_dt: dta['start_dt'],
          end_dt: dta['end_dt'],
          no_of_days: dta['no_of_days'],
          is_holiday: '',
          is_pub_holiday: '',
          min_start_date: dta['min_start_date']?dta['min_start_date']:new Date(),
          min_end_date: dta['min_end_date']?dta['min_end_date']:new Date(),
          max_start_date: new Date(),
          max_end_date: new Date()

        }))
    }
    else {
      if (this.formarraylength != 0) {
        // console.log(control.at(this.formarraylength - 1).getRawValue()['end_dt'], '==');
      }

      let startdt = (this.formarraylength != 0 ? control.at(this.formarraylength - 1).getRawValue()['end_dt'] : '');
      control.push(
        this.formBuilder.group({
          leave_detail_id: 0,
          leave_id: 0,
          leave_type_id: [],
          is_paid_leave: 0,
          is_half_day: 0,
          allow_half_day: 1,
          start_dt: [moment(startdt, "YYYY-MM-DD").add(1, 'day')],
          end_dt: [''],
          no_of_days: 0,
          is_holiday: '',
          is_pub_holiday: '',
          min_start_date: new Date(),
          min_end_date: new Date(),
          max_start_date: new Date(),
          max_end_date: new Date()

        }))

    }
    control.controls.forEach((group) => {
      group.get('leave_type_id')!.valueChanges.subscribe((value: any) => {
        const leaveTypeId = value;
        const leaveBalance = this.leaveTypes.filter((x: any) => x.leave_type_id === leaveTypeId)[0];
        if (leaveBalance) {
          const isPaidLeave = leaveBalance.is_loss_of_pay == false ? 1 : 0;
          group.patchValue({ is_paid_leave: isPaidLeave });
        } else {
          group.patchValue({ is_paid_leave: 0});
        }

      });
      
      this.revalidateFormArray()
    });
  }
  revalidateFormArray() {
    let control = <FormArray>this.manageForm.controls['leave_details_type'];

    control.controls.forEach((group) => {
      group.get('leave_type_id')!.setValidators([Validators.required]);
      group.get('start_dt')!.setValidators([Validators.required]);
      group.get('end_dt')!.setValidators([Validators.required]);

      group.get('leave_type_id')!.updateValueAndValidity();
      group.get('start_dt')!.updateValueAndValidity();
      group.get('end_dt')!.updateValueAndValidity();
    });

  }
  get leaveInformationRefGroup() {
    return this.manageForm.get('leave_details_type') as FormArray
  }
  async onSubmit(form: any) {
    this.form_validation = "";
    this.revalidateFormArray()

    this.manageFormSubmited = true;

    if (form.valid) { 
       var subdata = this.manageForm.getRawValue();
      var oParser = new DOMParser();
      var oDOM = oParser.parseFromString(this.manageForm.value.leave_reason, "text/html");
      var subdataDesc = oDOM.body.innerText;
    
      var transactions_data = [];
      var transactions_array = subdata.leave_details_type;
      for (var i in transactions_array) {
        // transactions_array[i]['is_half_day']==true?1:0
        transactions_data.push("("
          + transactions_array[i]['leave_detail_id'] + ","
          + transactions_array[i]['leave_id'] + ","
          + transactions_array[i]['leave_type_id'] + ",'"
          + (moment(transactions_array[i]['start_dt']).format('YYYY-MM-DD')) + "','"
          + (moment(transactions_array[i]['end_dt']).format('YYYY-MM-DD')) + "',"
          + (transactions_array[i]['no_of_days']) + ")")

      }
      var oParser = new DOMParser();
      var oDOM = oParser.parseFromString(this.manageForm.value.leave_reason, "text/html");
      var subdataDesc = oDOM.body.innerText;
      let postDta =
      {
        scheema: "hr", procname: "manage_leave_new",
        vals: [
         
          +subdata.leave_id,
          +subdata.employee_id,
          subdataDesc + "",
          +subdata.halfday_on_start_dt,
          +subdata.halfday_on_end_dt,
          transactions_data
        ]
      };
      let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
      var data = result['data'];
      if (data.length > 0) {
        if (subdata.leave_id == 0) {
          this.cs.openSnackBar("Added Successfully");
          this.CloseForm(1);
        } else {
          this.cs.openSnackBar("Updated Successfully");
          this.CloseForm(1);
        }
      } else {
        this.cs.openSnackBar('Some Error occured');
      }
    } else {
      this.form_validation = "Fill out All mandatory Fields";
    }

  }
  ngOnInit(): void {
    this.editor1 = new Editor()
  }
  ngOnDestroy(): void {
    this.editor1.destroy();
  }
}
