import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { CommonService } from '../../../../../../src/shared/common.service';
import { SharedModule } from '../../../../../../src/shared/shared.module';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LeaveFormComponent } from '../leave-form/leave-form.component';
import moment from 'moment';
import { data } from 'jquery';

@Component({
  selector: 'app-leave-details',
  standalone: true,
  imports: [
    CommonModule, SharedModule, TranslateModule,LeaveFormComponent,
  ],
  templateUrl: `./leave-details.component.html`,
  styleUrl: './leave-details.component.css',

})
export class LeaveDetailsComponent {
  public recordDetails: any = {};
  public dialogRef: any;
  public leaveDetails: any = {};
  public leaveReqStatusLogs: any = [];
  public Transactionslist: any = [];
  public remarkForm: any = FormGroup;
  public detailsprofilePic: any = '';
  public leaveData: any = {};
  public detailType = 0;
  public leaveTypes: any = [];
  public statusId:any='';
  public formData = { form_type: "a", form_id: "" };
  public holidaysList: any = [];
  public enableActionButtons: boolean = true;
  @Input() set record_details(data: any) {
    this.recordDetails = data;
    this.enableActionButtons = true;
    this.getRecordDetails();
   
  }
  @Output() newLeaveEvent = new EventEmitter<string>();
  @Output() DetailsCloseEvent = new EventEmitter<string>();

  public showDetails = false;
  form_id = '';
  @ViewChild("formdialogRef") formdialogRef!: TemplateRef<any>;
  constructor(public dialog: MatDialog, public cs: CommonService, private formBuilder: FormBuilder) {
    this.remarkForm = this.formBuilder.group({
      remarks: ['', Validators.required],
      leave_transactions_type:this.formBuilder.array([]),
    });
  }
  async getRecordDetails() {
    let postDta = [
      { scheema: "hr", procname: "get_leave_details", vals: [this.recordDetails.record_id, this.cs.userSession.org_id] },
      { scheema: "hr", procname: "get_leave_req_status_logs", vals: [this.recordDetails.record_id, this.cs.userSession.org_id] },      
      { scheema: "hr", procname: "get_holidays_list", vals: ['',this.cs.userSession.org_id,20, 0] },
      { scheema: "hr", procname: "get_leave_transaction_details", vals: [this.recordDetails.record_id] }
    ];
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/caldbprocfns", info: postDta }));
    this.leaveDetails = result['data']['get_leave_details'][0];
    // this.newLeaveEvent.emit(this.leaveDetails);

    //  this.leaveReqStatusLogs = result['data']['get_leave_req_status_logs'];
    this.showDetails = true;
    if (result['data']['get_leave_req_status_logs'].length > 0) {
      this.leaveReqStatusLogs = result['data']['get_leave_req_status_logs'];
    }
    if (result['data']['get_leave_transaction_details'].length > 0) {
      this.Transactionslist = result['data']['get_leave_transaction_details'];
    }
    if (result['data']['get_holidays_list'].length > 0) {
      this.holidaysList = result['data']['get_holidays_list'];
    }
    this.getLeaveinfo();
  }
 async getLeaveinfo(){
    if(this.Transactionslist.length>0){
      this.cs.clearFormArray(this.remarkForm.get('leave_transactions_type') as FormArray);
      for(var i in this.Transactionslist){
        this.addLeaveTransaction(this.Transactionslist[i])
      }}
  }
  updateData(data: any) {
    this.leaveDetails.start_dt = data.start_dt;
    this.leaveDetails.end_dt = data.end_dt;
    this.leaveDetails.no_of_days = data.no_of_days;
    this.leaveDetails.remarks = data.remarks;
    // this.newLeaveEvent.emit(this.leaveDetails);
  }
  closeLeaveForm(data: any) {
    this.detailType = 0;
    if (data.type == 1) {
      this.newLeaveEvent.emit(data);
      this.getRecordDetails();
    }
  }
  openLeaveForm(id: any, form_type: any) {
    this.detailType = 1;
    this.formData = { form_type: "e", form_id: id }

  }
  async deleteRecord(id: any) {
    let postDta =
    {
      scheema: "hr", procname: "delete_leave_request",
      vals: [id, this.cs.userSession.user_id]
    };
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
    if (result.status == 1) {
      this.cs.openSnackBar("Updated Successfully");
    }
  }
  openForm(status: any, leaveDetails: any) {
    this.statusId=status;

    if (+status) {
      this.remarkForm.get('remarks').addValidators(Validators.required);

    } else {
      this.remarkForm.get('remarks').clearValidators();
    }
    this.dialogRef = this.dialog.open(this.formdialogRef, {
      disableClose: true,
      width: "35vw",
      panelClass: "dialog-responsive",
      data: {
        status: status,
        leaveData: leaveDetails,
        userSession:this.cs.userSession,
        Transactionslist:this.Transactionslist
      }
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result.type == 1) {
        this.getRecordDetails();
        this.newLeaveEvent.emit(result.type);
        this.remarkForm.reset()
      } else {
        this.remarkForm.reset()
      }

    });
    this.form_id = status;
    this.leaveData = leaveDetails;
  }
  addLeaveTransaction(dta:any){
    let control = <FormArray>(this.remarkForm.controls['leave_transactions_type']);
    let today = new Date();
    if (dta != '') { 
      for (let currentDate = moment(dta.start_dt); currentDate <= moment(dta.end_dt); currentDate.add(1, 'days')) {
        const formattedDate = currentDate.format('YYYY-MM-DD') ;
        const weekOffDay = (this.cs.weeksList[+this.cs.userSession.emp_weekly_off-1]&&this.cs.weeksList[+this.cs.userSession.emp_weekly_off-1].name);
        let holidayfilter = this.holidaysList.filter((x: any) => moment(x.holiday_date).format('YYYY-MM-DD') === formattedDate)[0];
        if(formattedDate <= (moment(today).format('YYYY-MM-DD'))){
          this.enableActionButtons = false;
        }
        if ((moment(currentDate).day() === 0)  || (moment(currentDate).format('dddd') === weekOffDay) || (currentDate.day() === 6 && currentDate.date() >= 8 && currentDate.date() <= 14)) {
          continue;
        }
        else if(holidayfilter){
          continue;
        }
        let paidLeave=dta.is_loss_of_pay==true?0:1
        let startDate = moment(dta.start_dt).format('YYYY-MM-DD')
        let endDate= moment(dta.end_dt).format('YYYY-MM-DD')
        if(currentDate.format('YYYY-MM-DD') == startDate&&  currentDate.format('YYYY-MM-DD') ==endDate){
          paidLeave= (Math.max(paidLeave - (this.leaveDetails['halfday_on_start_dt'] ? 0.5 : 0) - (this.leaveDetails['halfday_on_end_dt'] ? 0.5 : 0), 0))
        }
        else if(currentDate.format('YYYY-MM-DD') == startDate){
          paidLeave=  paidLeave - (this.leaveDetails['halfday_on_start_dt'] ? 0.5 : 0)
        }
        else if( currentDate.format('YYYY-MM-DD') == endDate){
          paidLeave=  paidLeave -(this.leaveDetails['halfday_on_end_dt'] ? 0.5 : 0)
        }
        control.push(
          this.formBuilder.group({
          leave_detail_id: dta['leave_detail_id'],
          leave_id: dta['leave_id'],
          leave_date: currentDate.format('YYYY-MM-DD'),
          leave_type_id: dta['leave_type_id'],
          // (Math.max(paidLeave - (dta['halfday_on_start_dt'] ? 0.5 : 0) - (dta['halfday_on_end_dt'] ? 0.5 : 0), 0))
          is_paid_leave:paidLeave,
          is_half_day: (this.leaveDetails['halfday_on_start_dt'] && this.leaveDetails['halfday_on_end_dt'])==true?1:(this.leaveDetails['halfday_on_start_dt']==true?1:(this.leaveDetails['halfday_on_end_dt']==true?1:0)),
          status:this.statusId
        }))
      }
    }
    else{
    }
  }
  async updateStatus(form:any) {
    var transactions_data = [];
    var transactions_array = this.remarkForm.value.leave_transactions_type;
      for (var i in transactions_array) {
        transactions_data.push("(0" + ","
          + transactions_array[i]['leave_id'] + ",'"
          + moment(transactions_array[i]['leave_date']).format('YYYY-MM-DD') + "'" + ","
          + transactions_array[i]['leave_type_id'] + ","
          + transactions_array[i]['is_paid_leave'] + ","
          + transactions_array[i]['is_half_day'] +","
          + transactions_array[i]['status'] + ")")
      }
    let postDta =
    {
      scheema: "hr", procname: "update_leave_req_status_new",
      vals: [transactions_data,this.form_id, this.cs.userSession.user_id, this.leaveDetails.leave_id, this.cs.userSession.org_id, this.remarkForm.value.remarks]
    };
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
    this.dialogRef.close({ type: 1 });
    // if (result.length > 0) {
    if (this.leaveDetails.leave_id == 0) {
      this.cs.openSnackBar("Added Successfully");
    } else {
      this.cs.openSnackBar("Updated Successfully");
    }
  }
  public closeform(type = 0) {
    this.dialogRef.close({ type: type });
  }
  public closeDetails(type:any){
    this.DetailsCloseEvent.emit(type)
  }
  ngOnInit(): void {
  }

}
