import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Router, RouterOutlet } from '@angular/router';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { LeaveDetailsComponent } from '../../../projects/hr/src/app/leaves/leave-details/leave-details.component';
import { CommonService } from '../../shared/common.service';
@Component({
  selector: 'app-notficationsnack',
  standalone: true,
  imports: [
    CommonModule, SharedModule, RouterOutlet,LeaveDetailsComponent
  ],
  templateUrl: './notificationsnack.html',
  styleUrl: './notficationsnack.component.css'
})
export class NotficationsnackComponent { 
  public recordDetails:any;
  public records_loaded=0;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,public router:Router, private _snackRef: MatSnackBarRef<NotficationsnackComponent>,public cs:CommonService) {
    console.log(this.data,'notification data')

   }
   openNotification(){
    this.recordDetails={record_id:this.data.id};
    this.cs.opensnackView(this.recordDetails);

    // this._snackRef.afterDismissed().subscribe(info => {
    //   if (info.dismissedByAction === true) {
    //   } 
    //   })
    // this.router.navigate([this.data.note_details[3]]);
    this._snackRef.dismiss();
   }
   closeNotification(){
    this._snackRef.dismiss();
   }
 
  Leaveformclose(type:any){
    this._snackRef.dismiss();
  }
}
