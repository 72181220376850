import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { lastValueFrom } from 'rxjs';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../../src/shared/shared.module';;
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../../../src/shared/common.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-leave-history',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, TranslateModule, InfiniteScrollModule],
  templateUrl: './leave-history.component.html',
  styleUrls: ['./leave-history.component.css']
})
export class LeaveHistoryComponent implements OnInit {
  public empLeaveBalances: any = [];
  public leaveBalancesDetails: any = [];
  public recordDetails: any = {};
  public leave_details: number = 0
  @Input() set record_details(data: any) {
    this.recordDetails = data;
  // /  console.log(this.recordDetails)
    this.getEmpLeaveTypeDetails();
  }

  public currentYear: number = 0;
  public showClose: number = 0;
  constructor(public cs: CommonService,) {
  }
  async getEmpLeaveTypeDetails() {
    this.currentYear = new Date().getFullYear();
   // console.log(this.recordDetails.emp_id, this.cs.userSession.org_id, this.currentYear, 0)
    this.leaveBalancesDetails = [];
    let postDta =
    {
      scheema: "hr", procname: "get_emp_leave_balances",
      vals: [this.recordDetails.emp_id, this.cs.userSession.org_id, this.currentYear, 0]
    };
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
    if (result['data'].length > 0) {
      this.leaveBalancesDetails = result['data'];
    } else {

    }
  }
  ngOnInit(): void {
  }
}
