<ng-container *ngIf="recordDetails.isfullLeaveHistoryTable" >
<div class="row mx-0 px-3" >
  <div class=" col-12 my-3 p-0 d-flex align-items-center justify-content-between">
    <h5 class="text-primary fw-bold m-0">{{'HR.LEAVEBALANCE'|translate}}</h5>
  </div>
  <div class="col-12 p-0 mb-3">
    <table  class="table table-bordered w-100">
      <thead class="sticky-header">
        <tr class="sticky-row">
          <th class="w-5 text-end">{{'HR.S_NO'|translate}}</th>
          <th class="w-15">{{'HR.LEAVE_TYPE'|translate}}</th>
          <th class="w-15 text-end">{{'CRM.PREVIOUS' |translate}} {{'HR.YEAR'|translate}}</th>
          <th class="w-15 text-end">{{'HR.CURRENT'|translate}} {{'HR.YEAR'|translate}}</th>
          <th class="w-15 text-end">{{'HR.AVAILED'|translate}}</th>
          <th class="w-15 text-end">{{'HR.REMAINING'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let empLeaveBalances of leaveBalancesDetails;let i=index" >
          <td class="text-end w-5">{{i+1}}</td>
          <td class="w-15">{{empLeaveBalances.leave_type_nm}} <span *ngIf="0<this.recordDetails.leaveId" class="mdi mdi-eye text-primary"></span></td>
          <td class="text-end w-15">{{empLeaveBalances.opening_balance_leaves}}</td>
          <td class="text-end w-15">{{empLeaveBalances.current_yr_leave_bal}}</td>
          <td class="text-end w-15">{{empLeaveBalances.leaves_used}}</td>
          <td class="text-end w-15">{{empLeaveBalances.available_leaves}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</ng-container>
<ng-container *ngIf="!recordDetails.isfullLeaveHistoryTable" >
<div class="row mx-0 my-3">
  <div class=" col-12 p-0 d-flex align-items-center">
    <div  class="table-responsive p-0 w-100" style="max-height:250px;overflow-y:auto;overflow-x:hidden">
    <table class="table  table-bordered my-0 w-100">
      <tbody>
          <tr *ngFor="let empLeaveBalances of leaveBalancesDetails;let i=index" >
            <td class="form-w-10" style="width:95%">{{empLeaveBalances.leave_type_nm}} <span class="text-end pull-right"></span></td>
           <td class="form-w-5 text-end">{{empLeaveBalances.available_leaves}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  </div>
</div>
</ng-container>
