<form [formGroup]="manageForm" (ngSubmit)="onSubmit(manageForm)">
  <div class="row mx-0 px-3 border-b-1">
    <div class="col-12 py-2 d-flex align-items-center px-0">
      <div class="col-auto pe-2">
        <button mat-button color="warn" class="light-bg-warn btn-custom back-btn" matTooltip="Back"
          (click)="CloseForm(0)">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <h4 class="m-0 text-primary d-flex align-items-center fw-bold" *ngIf="formData.form_type=='a'">
        {{'HR.ADD_LEAVE' | translate }}
      </h4>
      <h4 class="m-0 text-primary d-flex align-items-center fw-bold" *ngIf="formData.form_type=='e'">
        {{'HR.EDIT_LEAVE' | translate }}
      </h4>
    </div>
  </div>
  <div class="leave-form-height">
    <div class="row mx-0 px-3">
      <div class="col-12 p-0">
        <div class="row m-0 d-flex pt-3" *ngIf="!empSelEditMode">
          <div class="col-auto ps-0">
            <img [src]="cs.mediaUrl+'profile/'+manageForm.value.emp_img" alt="image"
              class="userprofile-img rounded-circle" alt="" onerror="this.onerror=null;this.src='./assets/images/onerror-img.png';">
          </div>
          <div class="col-auto p-0">
            <p class="fw-normal text-capitalize "><label>{{manageForm.value.emp_name}}
              </label> <small> ({{manageForm.value.emp_code}}) <br> {{manageForm.value.emp_disgnation}}</small>
              <button type="button" mat-button color="primary" *ngIf="employeesList.length>1" (click)="empSelEditMode=!empSelEditMode "
                class="light-bg-warn btn-custom back-btn mx-2" matTooltip="Click To Change">
                <span class="mdi mdi-pencil"></span>
              </button>
            </p>
          </div>
        </div>
        <div class="row mx-0 d-flex align-items-center justify-content-start mt-3" *ngIf="empSelEditMode">
          <div class="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ps-0">
            <!--  *ngIf="cs.access_rights.subordinate_record_access_level>1" -->
            <mat-form-field appearance="outline" floatLabel="always" class="filter-block">
              <mat-label> {{'COMMON.SELECT EMPLOYEE'|translate}}</mat-label>
              <mat-select formControlName="employee_id"
                (selectionChange)="employeeDetails();getEmpLeaveTypeDetails();empSelEditMode=!empSelEditMode">
                <mat-option [value]="employees.employee_id" *ngFor="let employees of employeesList">
                  {{employees.employee_code}}-{{employees.employee_name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mx-0 my-2 p-0">
        <div class="col-lg-12 p-0">
          <div class="row mx-0 pb-2 d-flex align-items-center justify-content-between">
            <div class="col-auto p-0">
              <h5 class="text-primary fw-bold m-0">{{'HR.LEAVE TRANSACTIONS'|translate}}<button type="button" mat-button color="primary"
                  class="light-bg-primary btn-custom back-btn mx-2" matTooltip="Click To Add"
                  (click)="addLeaveTransactions('')">
                  <span class="mdi mdi-plus"></span>
                </button></h5>
            </div>
            <div class="col-auto pr-2">
              <mat-checkbox value="Number" color="primary" formControlName="halfday_on_start_dt"
                (change)="updateNoofDays(0)">
                {{'HR.ON_2ND_HALF' | translate }}
              </mat-checkbox>
              <mat-checkbox value="Number" color="primary" formControlName="halfday_on_end_dt"
                (change)="updateNoofDays(formarraylength)">
                {{'HR.ON_1ST_HALF' | translate }}
              </mat-checkbox>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered m-0" style="max-width: 100%;">
              <thead class="sticky-header">
                <tr class="sticky-row">
                  <th style="width:25%">{{'HR.LEAVE_TYPE'|translate}}</th>
                  <th style="width:30%">{{'COMMON.Start_End_Date'| translate}}</th>
                  <th style="width:40%" class="text-center">{{'HR.LEAVE_DAYS'|translate}} </th>
                  <th class="text-center" style="width:5%">{{ 'COMMON.ACTIONS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container formArrayName="leave_details_type">
                  <ng-container
                    *ngFor="let leave_transaction of manageForm.get('leave_details_type').controls;let j=index ">
                    <ng-container [formGroupName]="j">
                      <tr>
                        <td style="width:25%">
                          <mat-form-field appearance="outline"  class="filter-block">
                            <mat-select formControlName="leave_type_id" placeholder="Click to Select" (selectionChange)="updateLeaveTypeSettings($event,j)">
                              <mat-option [value]="leaveType.leave_type_id" *ngFor="let leaveType of leaveTypes">
                                {{leaveType.leave_name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td style="width:30%">
                          <ng-container *ngIf="j==0">
                            <div class="row mx-0 d-flex align-items-center justify-content-center">
                            <div class="col-11 px-0">
                            <mat-form-field appearance="outline" class="filter-block" >
                              <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" [min]="leave_transaction.value.min_start_date" [max]="leave_transaction.value.min_end_date">
                                <input autocomplete="off" matStartDate   readonly
                                  formControlName="start_dt"  (dateChange)="updateNoofDays(j)">
                                <input autocomplete="off" 
                                 formControlName="end_dt"
                                  (dateChange)="updateNoofDays(j)" matEndDate readonly>
                              </mat-date-range-input>
                              <mat-datepicker-toggle class="" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                            </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="j>0">
                            <div class="row  mx-0 d-flex align-items-center justify-content-center">
                              <div class="col-auto px-0">
                                {{leave_transaction.value.start_dt | date:'MM/dd/YYYY'}} -
                              </div>
                              <div class="col-auto">
                                <mat-form-field appearance="outline" floatLabel="always" class="filter-block"
                                  style="width:130px !important">
                                  <mat-label>{{'PM.END_DATE'| translate}}</mat-label>
                                  <input autocomplete="off" matInput [matDatepicker]="picker1"  readonly
                                    [min]="leave_transaction.value.start_dt"   formControlName="end_dt"
                                    (click)="picker1.open()" (dateChange)="updateNoofDays(j)" [max]="leave_transaction.value.max_end_date">
                                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                  <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                          </ng-container>
                         
                        </td>
                    
                        <td class="text-center" style="width:40%">
                          {{leave_transaction.value.no_of_days}} {{leave_transaction.value.no_of_days>1? 'Days':'Day'}}<br>

                          <ng-container *ngIf="leave_transaction.value.is_holiday!=''"><b>Week Off On: </b>
                            <ng-container
                              *ngFor="let dt of leave_transaction.value.is_holiday.toString().split(','); let dti=index">
                              <ng-container *ngIf="dti>0">, </ng-container>{{dt |date:'mediumDate'}}
                            </ng-container></ng-container>
                          <br>
                          <ng-container *ngIf="leave_transaction.value.is_pub_holiday!=''"><b>Holiday On:</b>
                            <ng-container
                              *ngFor="let dt of leave_transaction.value.is_pub_holiday.toString().split(','); let dti=index">
                              <ng-container *ngIf="dti>0">, </ng-container>{{dt |date:'mediumDate'}}
                            </ng-container></ng-container>
                        </td>
                        <td class="text-center" style="width:5%">
                          <ng-container *ngIf="j>0">
                            <button type="button" mat-button color="warn" matTooltip="Delete"
                              *ngIf="cs.access_rights['delete_access']  ||  cs.userSession.account_role_order<=1"
                              class="light-bg-acent btn-custom mx-1" (click)="deleteLeaveType(j)">
                              <span class="mdi mdi-delete-forever"></span>
                            </button>
                          </ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mx-0  mt-3 d-flex align-items-start p-0">

        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 p-0">
          <label class="mb-3 text-primary fw-bold">{{'HR.REASON' | translate }}</label>
          <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor1" [toolbar]="toolbar"> </ngx-editor-menu>
            <ngx-editor [editor]="editor1" formControlName="leave_reason" [disabled]="false"
              [placeholder]="'Type here...'"></ngx-editor>
          </div>
          <!-- <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{'HR.REASON' | translate }}</mat-label>
            <textarea matInput type="text" placeholder="" formControlName="leave_reason" rows="6"></textarea>
          </mat-form-field> -->
          <mat-error
            *ngIf="(manageForm.get('leave_reason')?.dirty || manageFormSubmited) && manageForm.get('leave_reason')?.errors?.required"
            class="mat-error">{{ 'COMMON.REQUIRED' | translate }}</mat-error>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 ps-2 px-0">
          <h5 class="text-primary fw-bold">{{'HR.LEAVEBALANCE'|translate}}</h5>
          <app-leave-history [record_details]="recordDetails"
          ></app-leave-history>
        </div>
      </div>

    </div>
    <div class="row mx-0" *ngIf="form_validation!=''">
      <div class="col-12  py-3 text-center mat-error d-flex justify-content-center">
        <span *ngIf="form_validation!=''" style="color:red">{{form_validation}}</span>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="row py-3 d-flex align-items-center justify-content-start mx-0">
    <div class="col-auto p-0 mx-3">
      <button mat-raised-button color="primary" matTooltip="{{formData.form_type==='a'?'Submit':'Update'}}">
        <span class="mdi mdi-check-all mx-1"></span>{{formData.form_type==='a'? 'Submit' : 'COMMON.UPDATE' | translate}}</button>
      <button mat-stroked-button color="primary" matTooltip="Cancel" class="ms-3" (click)="CloseForm(0)">
        <span class="mdi mdi-close mr-1"></span>{{'COMMON.CANCEL' |translate }}</button>
    </div>
  </div>
</form>