<ng-container
  *ngIf="detailType==0  && (+cs.access_rights['view_detail_access']===1  ||  +cs.userSession.account_role_order<=1)">
  <div [hidden]="!showDetails">

    <div class="row m-0 px-3 py-2 d-flex align-items-center justify-content-between border-b-1">
      <div class="col-auto p-0 d-flex align-items-center">
        <button mat-button color="warn" class="light-bg-warn btn-custom back-btn me-2" matTooltip="Back"
          (click)="closeDetails(0)">
          <mat-icon>arrow_back</mat-icon>
        </button>

        <h4 class="m-0 text-primary p-0 mat-subtitle-1">#LE-{{leaveDetails.leave_code}}</h4>
      </div>
      <div class="col-xxl-4 col-lg-6 col-md-6 col-xl-6 col-sm-12 p-0 d-flex justify-content-end align-items-center ">
        <ng-container *ngIf="leaveDetails.leave_status_nm!=null">
          <p class="mx-2">
            <span [style.background-color]="'#'+leaveDetails.status_color_code" class="fw-bold ps-2"
              class="circle-dot mx-1"></span>
            <label class="m-0">{{leaveDetails.leave_status_nm}}</label>
          </p>
        </ng-container>
        <button
          *ngIf="((+cs.access_rights['delete_access']===1 ||  +cs.userSession.designation_order_level>=1 || leaveDetails.created_by == +cs.userSession.user_id)  && leaveDetails.status==1)"
          matTooltip="Cancel" mat-button color="warn" (click)="openForm(2,leaveDetails)"
          class="light-bg-acent btn-custom me-2 "><span class="mdi mdi-close"></span>
        </button>
        <button mat-button color="primary" matTooltip="Edit"
          *ngIf="((+cs.access_rights['delete_access']===1 ||  +cs.userSession.designation_order_level>=1 || leaveDetails.created_by == +cs.userSession.user_id)  && leaveDetails.status==1)"
          class="light-bg-primary btn-custom " (click)="openLeaveForm(leaveDetails.leave_id,'e')">
          <span class="mdi mdi-pencil"></span>
        </button>
      </div>
    </div>

    <div class="row m-3">
      <div class=" col-xxl-6 col-lg-6 col-md-6 col-xl-6 col-sm-12 p-0">
        <div class="row m-0 d-flex">
          <div class="col-auto ps-0 pe-2">
            <img [src]="cs.mediaUrl+'profile/'+cs.userSession['account_img']"
              onerror="this.onerror=null;this.src='./assets/images/onerror-img.png';" alt="image"
              class="userprofile-img rounded-circle" alt="">
          </div>
          <div class="col-auto p-0">
            <p class="fw-normal text-capitalize "><label>{{leaveDetails.employee_name}}
              </label> <small> ({{leaveDetails.employee_code}}) <br> {{leaveDetails.designation_name}}</small></p>
          </div>
        </div>
      </div>
      <div class=" col-xxl-6 col-lg-6 col-md-6 col-xl-6 col-sm-12 p-0">
        <p class=" d-flex align-items-center"> <small class="mdi mdi-calendar-multiselect pr-2"></small> <label
            class="me-2">{{leaveDetails.no_of_days}} {{leaveDetails.no_of_days>1? 'Days':'Day'}}</label>
          <ng-container
            *ngIf="leaveDetails.start_dt!==leaveDetails.end_dt && leaveDetails.no_of_days>1">({{leaveDetails.start_dt |
            date:'mediumDate'}} - {{leaveDetails.end_dt | date:'mediumDate'}})</ng-container>
          <ng-container
            *ngIf="leaveDetails.start_dt==leaveDetails.end_dt && leaveDetails.no_of_days==1">({{leaveDetails.start_dt |
            date:'mediumDate'}})</ng-container>
        </p>
        <!-- <p class="fw-normal ">{{'HR.DAYS' | translate }}: <label></label></p> -->
      </div>
    </div>

    <div class="row mx-0 px-3 pb-2 d-flex align-items-center justify-content-end">


      <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12 text-end p-0">
{{+cs.access_rights['request_approval_rejections']===1}}--{{+ cs.userSession.designation_order_level<=1}} ==={{leaveDetails.status==1}}:::: {{+ cs.userSession.user_id!=leaveDetails.employee_id}}
        <button
          *ngIf="((+cs.access_rights['request_approval_rejections']===1) ||  + cs.userSession.designation_order_level<=1) && ((leaveDetails.status==1) && (+ cs.userSession.user_id!=leaveDetails.employee_id)) "
          mat-button color="primary" matTooltip="Click to Approve Leave Request" class="light-bg-primary mb-0 me-3"
          (click)="openForm(3,leaveDetails)" color="primary"><span
            class="mdi mdi-checkbox-marked-circle mr-2"></span>{{'HR.APPROVE' | translate }}
        </button>
        <button
          *ngIf="(+cs.access_rights['request_approval_rejections']===1 ||  +cs.userSession.designation_order_level<=1) && ((leaveDetails.status==1) && (+ cs.userSession.user_id!=leaveDetails.employee_id))"
          (click)="openForm(4,leaveDetails)" class="light-bg-acent" matTooltip="Click to Reject Leave Request"
          mat-button color="warn"><span class="mdi mdi-close-circle mr-2"></span>{{'HR.REJECT' | translate }}
        </button>
        <button
          *ngIf="(+cs.userSession.user_id==leaveDetails.employee_id) && leaveDetails.status==3 && enableActionButtons"
          (click)="openForm(5,leaveDetails)" mat-button color="primary" class="light-bg-primary mb-0 me-0"
          matTooltip="revoke" color="primary">{{'HR.APPLY_FOR_REVOKE' | translate }}
        </button>
        <button
          *ngIf="((leaveDetails.status==5) && (+ cs.userSession.user_id!=leaveDetails.employee_id)) && (+cs.access_rights['request_approval_rejections']===1 ||  +cs.userSession.designation_order_level<=1) "
          (click)="openForm(6,leaveDetails)" mat-button color="primary" class="light-bg-primary  me-3"
          color="primary"><span class="mdi mdi-checkbox-marked-circle mr-2"></span>{{'HR.APPROVE' | translate }}
        </button>
        <button
          *ngIf="((leaveDetails.status==5) && (+ cs.userSession.user_id!=leaveDetails.employee_id)) && (+cs.access_rights['request_approval_rejections']===1 ||  +cs.userSession.designation_order_level<=1) "
          (click)="openForm(7,leaveDetails)" class="light-bg-acent" mat-button color="warn"><span
            class="mdi mdi-close-circle mr-2"></span>{{'HR.REJECT' | translate }}
        </button>
      </div>

    </div>

    <div class="row mx-3 my-2 d-flex align-items-center">
      <label class="p-0 mb-2 text-primary">{{'HR.REASON'|translate}}</label>
      <mat-divider></mat-divider>
      <div class="row  mx-0 px-0 py-3">
        <div class="col-12 p-0">
          <p class="fw-normal text-capitalize " [innerHTML]="leaveDetails.leave_reason">
          </p>
        </div>
      </div>
    </div>
    <div class=" listscroll leavedetails">
      <div class="row mx-3 my-2 d-flex align-items-center">
        <label class="p-0 mb-2 text-primary">Leave Days</label>
        <mat-divider></mat-divider>

        <div class="row mx-0 px-0 py-3">
          <div class="col-xl-11 col-xxl-10 p-0 m-0">
            <div class="table-responsive p-0">
              <table class="table table-bordered">
                <thead class="sticky-header">
                  <tr>
                    <th class="text-end dtl-w-5">{{'HR.S_NO' |translate}}</th>
                    <th class="dtl-w-20">{{'HR.LEAVE_TYPE' | translate }}</th>
                    <th class="dtl-w-25">{{'HR.DATES'|translate}}</th>
                    <th class="dtl-w-9">{{'HR.NOOFDAYS' | translate }}</th>
                    <!-- <th>{{'HR.LEAVE_TYPE' | translate }}</th> -->
                  </tr>
                </thead>
                <tbody *ngIf="Transactionslist.length>0">
                  <tr *ngFor="let Transactionrow of Transactionslist;let i=index">
                    <td class="text-end">{{i+1}}</td>
                    <td class="">{{Transactionrow.leave_name}}</td>
                    <td class=""><span class="mdi mdi-calendar-multiselect pr-2"></span>{{Transactionrow.start_dt |
                      date:'MMM d, y'}} - {{Transactionrow.end_dt | date:'MMM d, y'}} </td>
                    <td class="text-end">{{Transactionrow.no_of_days}}</td>
                    <!-- <td class="">@if(Transactionrow.is_paid_leave){
                  Paid
                  }
                  @else(){
                  Unpaid
                  }@if(Transactionrow.is_half_day){
                  (Half Day)
                  }
                </td> -->
                  </tr>
                </tbody>
                <tbody *ngIf="Transactionslist.length==0">
                  <tr>
                    <td colspan="5" class="text-center">
                      <p>No Records found...</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-3 my-2 d-flex align-items-center">
        <label class="p-0 mb-2 text-primary">Progressions</label>
        <mat-divider></mat-divider>
        <div class="row mx-0 px-0 py-3">
          <div class="col-xl-11 col-xxl-10 p-0 m-0">
            <div class="table-responsive p-0">
              <table class="table table-bordered w-100">
                <thead class="sticky-header">
                  <tr>
                    <!-- <th class="text-end" style="width:5%">{{'HR.S_NO'|translate}}</th>
                <th style="width:20%">{{'COMMON.STATUS' | translate }}</th>
                <th style="width:40%">{{'HR.COMMENTS' | translate }}</th>
                <th style="width:15%">{{'COMMON.ACTION_ON' | translate }}</th>
                <th style="width:20%">{{'HR.ACTION_BY' | translate }}</th> -->
                    <th class="text-end dtl-w-5">{{'HR.S_NO'|translate}}</th>
                    <th class="dtl-w-15">{{'COMMON.STATUS' | translate }}</th>
                    <th class="dtl-w-25">{{'HR.COMMENTS' | translate }}</th>
                    <th class="dtl-w-9">{{'COMMON.ACTION_ON' | translate }}</th>
                    <th class="dtl-w-20">{{'HR.ACTION_BY' | translate }}</th>
                  </tr>
                </thead>
                <tbody *ngIf="leaveReqStatusLogs.length>0">
                  <tr *ngFor="let leaveReqStatusLog of leaveReqStatusLogs;let i=index">
                    <td class="text-end" style="width:5%">{{i+1}}</td>
                    <td style="width:20%">
                      <p class="d-flex align-items-center ">
                        <!-- <ng-container *ngIf="leaveReqStatusLog.status_nm!=null">
                      <span [style.background-color]="'#'+leaveReqStatusLog.status_color_code" class="fw-bold "
                        class="circle-dot me-1"></span>
                      <label class="m-0">{{leaveReqStatusLog.status_nm}}</label>
                    </ng-container> -->
                        <ng-container *ngIf="leaveDetails.leave_status_nm!=null">
                          <p [style.background-color]="'#'+leaveDetails.status_color_code"
                            class="fw-bold ps-2 text-capitalize" class="circle-dot me-1">
                          </p>
                          <label>{{leaveReqStatusLog.status_nm}}</label>
                        </ng-container>
                      </p>
                    </td>
                    <td style="width:40%" [innerHTML]="leaveReqStatusLog.remarks"></td>
                    <td style="width:15%">{{leaveReqStatusLog.last_updation_date | date:'MMM d, y'}}</td>
                    <td style="width:20%">{{leaveReqStatusLog.last_updated_by}}</td>
                  </tr>
                </tbody>
                <tbody *ngIf="leaveReqStatusLogs.length==0">
                  <tr>
                    <td colspan="5" class="text-center">
                      <p>No Records found...</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- <mat-tab-group class="p-0">
      <mat-tab label="Reason" class="p-3">
        <div class="row  mx-0 p-3">
          <div class="col-12 p-0 reason-scroll">
            <p class="fw-normal text-capitalize " [innerHTML]="leaveDetails.leave_reason">
            </p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Details" >
        <div class="row mx-0 px-3 pt-3">
          <div class="table-responsive p-0">
            <table class="table table-bordered">
              <thead class="sticky-header">
                <tr>
                  <th class="text-center dtl-w-5">S.No</th>
                  <th class="dtl-w-25">{{'HR.LEAVE_NAME' | translate }}</th>
                  <th class="dtl-w-20">Date's</th>
                  <th  class="dtl-w-9">Leave {{'HR.DAYS' | translate }}</th>
                </tr>
              </thead>
              <tbody *ngIf="Transactionslist.length>0">
                <tr *ngFor="let Transactionrow of Transactionslist;let i=index">
                  <td class="text-end">{{i+1}}</td>
                  <td class="">{{Transactionrow.leave_name}}</td>
                  <td class=""><span class="mdi mdi-calendar-multiselect pr-2"></span>{{Transactionrow.start_dt | date:'MMM d, y'}} - {{Transactionrow.end_dt | date:'MMM d, y'}} </td>
                  <td class="text-end">{{Transactionrow.no_of_days}}</td>
                  
                </tr>
              </tbody>
              <tbody *ngIf="Transactionslist.length==0">
                <tr>
                  <td colspan="5" class="text-center">
                    <p>No Records found...</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Progressions">
        <div class="row mx-0 px-3 pt-3">
          <div class="table-responsive p-0">
            <table class="table table-bordered w-100">
              <thead class="sticky-header">
                <tr>
                  <th class="text-center" style="width:5%">S.No</th>
                  <th style="width:20%">{{'COMMON.STATUS' | translate }}</th>
                  <th style="width:40%">{{'HR.COMMENTS' | translate }}</th>
                  <th style="width:15%">{{'HR.ON' | translate }}</th>
                  <th style="width:20%">{{'HR.ACTION_BY' | translate }}</th>
                </tr>
              </thead>
              <tbody *ngIf="leaveReqStatusLogs.length>0">
                <tr *ngFor="let leaveReqStatusLog of leaveReqStatusLogs;let i=index">
                  <td class="text-end" style="width:5%">{{i+1}}</td>
                  <td style="width:20%">
                    <p class="d-flex align-items-center ">
                      
                      <ng-container *ngIf="leaveDetails.leave_status_nm!=null">
                        <p [style.background-color]="'#'+leaveDetails.status_color_code" class="fw-bold ps-2 text-capitalize"
                          class="circle-dot me-1">
                        </p>
                        <label>{{leaveReqStatusLog.status_nm}}</label>
                      </ng-container>
                    </p>
                  </td>
                  <td style="width:40%" [innerHTML]="leaveReqStatusLog.remarks"></td>
                  <td style="width:15%">{{leaveReqStatusLog.last_updation_date | date:'MMM d, y'}}</td>
                  <td style="width:20%">{{leaveReqStatusLog.last_updated_by}}</td>
                </tr>
              </tbody>
              <tbody *ngIf="leaveReqStatusLogs.length==0">
                <tr>
                  <td colspan="5" class="text-center">
                    <p>No Records found...</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group> -->

  </div>
  <div *ngIf="!showDetails" class=" row d-flex align-items-center justify-content-center">
    <div class="col-4 col-sm-8 col-xs-10">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="detailType==1">
  <app-leave-form [form_data]="formData" (newItemEvent)="updateData($event)"
    (formCloseEvent)="closeLeaveForm($event)"></app-leave-form>
</ng-container>
<ng-template #formdialogRef let-data>
  <form [formGroup]="remarkForm" (ngSubmit)="updateStatus(remarkForm)">
    <div class="row mx-0 mb-3">
      <div class="col-12 p-0 d-flex align-items-center justify-content-between">
        <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="data.status==3 || data.status==6">
          {{'HR.REASON_FOR_APRROVE' | translate }}</h4>
        <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="data.status==4 || data.status==7">
          Reason For Rejection</h4>
        <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="data.status==5">
          Reason For Revoke</h4>
        <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="data.status==2">
          Reason For cancellation</h4>
        <span class="cursor" (click)="closeform(0)">
          <mat-icon matTooltip="Close">close</mat-icon>
        </span>
      </div>
    </div>
    <hr>
    <div class="row py-2">
      <div class="col-12" >
        <!-- add validations -->
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{'HR.COMMENTS' | translate }}</mat-label>
          <textarea matInput placeholder="" formControlName="remarks"></textarea>
        </mat-form-field>
      </div>
    </div>
    <hr>
    <div class="d-flex py-2">
      <button mat-raised-button color="primary" matTooltip="update"><span
          class="mdi mdi-check-all mr-1"></span>{{'COMMON.UPDATE' | translate }}</button>
      <button type="button" mat-stroked-button color="primary" class="ms-3" (click)="closeform(0)"
        matTooltip="Cancel"><span class="mdi mdi-close mr-1"></span>{{'COMMON.CANCEL' |
        translate }}</button>
    </div>
  </form>
</ng-template>